export class QueryString {
  constructor(private map: Record<string, string | number> = {}) {}

  set(key: string, ...value: any[]) {
    if (value.length) {
      this.map[key] = value.join(",")
    }
    return this
  }

  toString() {
    const qs = Object.keys(this.map)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.map[key])}`)
      .join("&")

    return qs ? `?${qs}` : ""
  }
}
