import { Fetcher, GraphiQL } from "graphiql"
import GraphiQLExplorer from "graphiql-explorer"
import "graphiql/graphiql.css"
import type { GraphQLSchema } from "graphql"
import { buildClientSchema, getIntrospectionQuery, parse } from "graphql"
import React, { Component } from "react"

import { GraphQLClient } from "../clients/graphql.client"
import "./App.css"

const DEFAULT_QUERY = ``

type State = {
  schema?: GraphQLSchema
  query: string
  explorerIsOpen: boolean
}

export class App extends Component<any, State> {
  state = { schema: undefined, query: DEFAULT_QUERY, explorerIsOpen: true }

  private _graphiql: GraphiQL | null = null
  private qs = Object.fromEntries(new URLSearchParams(window.location.search))
  private graphqlClient = new GraphQLClient()

  async componentDidMount() {
    const { data, success } = await this.graphqlClient.query(
      {
        query: getIntrospectionQuery(),
      },
      this.qs
    )

    if (success) {
      const editor = this._graphiql?.getQueryEditor()

      editor.setOption("extraKeys", {
        ...(editor.options.extraKeys || {}),
        "Shift-Alt-LeftClick": this._handleInspectOperation,
      })

      this.setState({ schema: buildClientSchema(data.data) })
    }
  }

  private _handleInspectOperation = (cm: any, mousePos: { line: number; ch: number }) => {
    const parsedQuery = parse(this.state.query || "")

    if (!parsedQuery) {
      console.error("Couldn't parse query document")
      return null
    }

    const token = cm.getTokenAt(mousePos)
    const start = { line: mousePos.line, ch: token.start }
    const end = { line: mousePos.line, ch: token.end }
    const relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end),
    }

    const position = relevantMousePos

    const def = parsedQuery.definitions.find((definition) => {
      if (!definition.loc) {
        console.log("Missing location information for definition")
        return false
      }

      const { start, end } = definition.loc
      return start <= position.start && end >= position.end
    })

    if (!def) {
      console.error("Unable to find definition corresponding to mouse position")
      return null
    }

    const operationKind =
      def.kind === "OperationDefinition"
        ? def.operation
        : def.kind === "FragmentDefinition"
        ? "fragment"
        : "unknown"

    const operationName =
      def.kind === "OperationDefinition" && !!def.name
        ? def.name.value
        : def.kind === "FragmentDefinition" && !!def.name
        ? def.name.value
        : "unknown"

    const selector = `.graphiql-explorer-root #${operationKind}-${operationName}`

    const el = document.querySelector(selector)
    el && el.scrollIntoView()
  }

  private _handleEditQuery = (query?: string) => query && this.setState({ query })

  private _handleToggleExplorer = () => {
    this.setState({ explorerIsOpen: !this.state.explorerIsOpen })
  }

  private _fetchResult: Fetcher = async (params, opts) => {
    const { data } = await this.graphqlClient.query(params, this.qs)
    return data
  }

  render() {
    const { query, schema } = this.state
    return (
      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={this._handleEditQuery}
          onRunOperation={(operationName) => this._graphiql?.handleRunQuery(operationName)}
          explorerIsOpen={this.state.explorerIsOpen}
          onToggleExplorer={this._handleToggleExplorer}
          // getDefaultScalarArgValue={getDefaultScalarArgValue}
          // makeDefaultArg={makeDefaultArg}
        />
        <GraphiQL
          ref={(ref) => (this._graphiql = ref)}
          fetcher={this._fetchResult}
          schema={schema}
          query={query}
          onEditQuery={this._handleEditQuery}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={() => this._graphiql?.handlePrettifyQuery()}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button
              onClick={() => this._graphiql?.handleToggleHistory()}
              label="History"
              title="Show History"
            />
            <GraphiQL.Button
              onClick={this._handleToggleExplorer}
              label="Explorer"
              title="Toggle Explorer"
            />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </div>
    )
  }
}
