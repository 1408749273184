import { safeParse } from "../utils/json.utils"
import { QueryString } from "../utils/queryString"

type Body = string | Record<string, any> | null
type RequestInfo = RequestInit & { qs?: Record<string, string> }

export class HttpClient {
  constructor(protected baseUrl: string) {}

  get<TResponse>(path: string, init?: RequestInfo) {
    return this.send<TResponse>(path, "GET", init)
  }

  post<TResponse>(path: string, data?: Body, init?: RequestInfo) {
    return this.send<TResponse>(path, "POST", this.prepareBody(data, init))
  }

  private prepareBody(data?: Body, init: RequestInfo = {}): RequestInfo {
    if (data) {
      if (typeof data === "object") {
        data = JSON.stringify(data)
        const headers = new Headers(init.headers)
        headers.set("Content-Type", "application/json")
        init.headers = headers
      }
    }

    init.body = init.body || (data as string)
    return init
  }

  async send<TResponse>(path: string, method: string, init: RequestInfo = {}) {
    init.method = init.method || method

    const qs = new QueryString(init.qs)
    const url = new URL(path + qs.toString(), this.baseUrl).toString()
    const response = await fetch(url, init)
    const body = await response.text()
    const { headers, status, ok: success } = response

    const isJsonResponse = response.headers //
      .get("content-type")
      ?.startsWith("application/json")

    const result = isJsonResponse ? safeParse<TResponse>(body) : body

    return {
      data: result as TResponse,
      status,
      success,
      headers,
    }
  }
}
