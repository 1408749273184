import { FetcherParams } from "graphiql"

import { config } from "../utils/config"

import { HttpClient } from "./http.client"

type GraphQLResponse = {
  data: any
  errors: any[]
  extensions?: any
}

export class GraphQLClient extends HttpClient {
  constructor() {
    super(config.gateway.url)
  }

  async query(params: Partial<FetcherParams>, qs?: Record<string, string>) {
    return this.post<GraphQLResponse>("content/v2", params, { qs })
  }
}
